@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    color: #404140;
    font-family: 'Sofia Pro', sans-serif;
    /* font-family: 'Barlow', sans-serif; */
}
.tandc ol {
    counter-reset: item;
}
.tandc li {
    display: block;
}
.tandc li:before {
    content: counters(item, ".") ". ";
    counter-increment: item;
    color: #666;
}
.tancd h3, .tandc li h3 {
    display: inline;
}
