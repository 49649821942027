.video{
    width: 100%;
    height: 469px;    
}

@media screen and (max-width:1300px) {
    .video{
        height: 400px;
    }
}


@media screen and (max-width:1100px) {
    .video{
        height: 308px;
    }
}

@media screen and (max-width:1000px) {
    .video{
        height: 313px;
    }
}

@media screen and (max-width:800px) {
    .video{
        height: 250px;
    }
}
@media screen and (max-width:600px) {
    .video{
        height: 180px;
    }
}
